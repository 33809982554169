const appConfig = {
    apiPrefix: '',
    authenticatedEntryPath: '/overview',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
