import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {db} from '../../firebase'
import { doc, getDoc } from "firebase/firestore"

function useAuth()
 {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useQuery()
    const { token, signedIn } = useSelector((state) => state.auth.session)
    const [userData, setUserData] = useState(null);

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.user) {
                const { email, uid, accessToken } = resp.user

                const userUid = uid;
                const getUserData = async () => {
                    const docRef = doc(db, 'users', userUid);
                    const docSnap = await getDoc(docRef);
        
                    if (docSnap.exists()) {
                        dispatch(setUser({
                            fields: docSnap.data()
                        }))
                    } else {
                        console.log("No such document!");
                    }
                };
                getUserData();

                dispatch(setUser({
                    uid: uid,
                    email: email
                }))

                dispatch(onSignInSuccess(accessToken))

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)

            if (resp.user) {
                const { email, uid, accessToken } = resp.user
                dispatch(onSignInSuccess(accessToken))
                dispatch(setUser({
                    uid: uid,
                    email: email
                }))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut
    }
}

export default useAuth