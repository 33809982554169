const FirebaseConfig = {
    apiKey: "AIzaSyBMzEl-6NJbFgnHYDqowv9jIjgcSjd7geo",
    authDomain: "cdpapp-381221.firebaseapp.com",
    projectId: "cdpapp-381221",
    storageBucket: "cdpapp-381221.appspot.com",
    messagingSenderId: "648031656841",
    appId: "1:648031656841:web:ca21c84d44de31a6e55f58",
    tenantId: "mlopssponsors-yd3rg"
  };
  
  
  
  export default FirebaseConfig