import { initializeApp } from 'firebase/app'
import {getAuth,signInWithEmailAndPassword,createUserWithEmailAndPassword,signOut} from 'firebase/auth'
import 'firebase/compat/auth'
import { getFirestore } from 'firebase/firestore'
import firebaseConfig from 'configs/firebase.config'

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
const currentUser = auth.currentUser
auth.tenantId = firebaseConfig.tenantId

export {
    db,
    auth,
    currentUser,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
}
