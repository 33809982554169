import React, { useEffect }  from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'

import * as rudderAnalytics from 'rudder-sdk-js';
rudderAnalytics.load("2Sq4pqSgpsz9FLvczP7f6MJaQdP", "https://danbmedefzavzlslreyxjgcjwlf.dataplane.rudderstack.com");

const environment = process.env.NODE_ENV

if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}

const Main = () => {
    const location = useLocation();

    useEffect(() => {
        rudderAnalytics.page(location.pathname);
    }, [location]);

    return (
        <Theme>
            <Layout />
        </Theme>
    );
}

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <Main />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App